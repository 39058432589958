<div class="lp-form-title">
  Sign in to Warp
</div>
<form [formGroup]="formInput" nz-form>
  <ng-container *ngFor="let key of formInputKeys">
    <div class="form-label-v1">{{getLabel(key)}}</div>
    <nz-form-item class="form-value-v1">
      <ng-container [ngSwitch]="key">
        <input *ngSwitchCase="'email'" #inputEmail nz-input 
          [formControlName]="key" type="email" name="email" 
          [placeholder]="getPlaceHolder(key)" autofocus autocomplete="on" (keyup)="onEmailKeyUp($event)">
        
        <ng-container *ngSwitchCase="'pw'">
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input #inputPw nz-input [type]="passwordVisible ? 'text' : 'password'" 
              [formControlName]="key" (keyup)="onPwKeyUp($event)" [placeholder]="getPlaceHolder(key)"/>
          </nz-input-group>
          <ng-template #suffixTemplate>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
          </ng-template>
        </ng-container>
      </ng-container>
    </nz-form-item>
  </ng-container>
</form>

<div class="forgot-pw-container">
  <div class="forgot-pw" (click)="onBtnForgotPw()" style="margin-right: 5px; text-align: right;">Forgot password</div>
</div>

<button nz-button class="lp-button-border submit" [disabled]="!canLogin || onProgress" (click)="onBtnLogin()">
  <i *ngIf="onProgress" class="ic-right" nz-icon nzType="loading" nzTheme="outline"></i>ENTER
</button>