import to from 'await-to-js';
import { Component } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Crypto } from '@services/crypto';
import { InputHelper } from "@services/input-helper";
import { ActivatedRoute } from "@angular/router";
import { Log } from "@services/log";
import { verifyAfterLogin } from "@services/auth.check-role";

@Component({
  selector: '[password-expired]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../public/style.scss', '../../styles/form-v1.scss']
})
export class PasswordExpired extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    oldPw: { label: 'Current Password:', required: true, placeHolder: 'Current password' },
    newPw: { label: 'New Password:', required: true, placeHolder: 'New password' },
    confirmPw: { label: 'Confirm Password:', required: true, placeHolder: 'Confirm password' }
  };

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  get canVerify() {
    return !this.onProgress && this.needUpdate;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async onBtnVerify() {
    this.startProgress();
    const data: any = this.getFormData_JSON(true);
    if (data.newPw !== data.confirmPw) {
      this.showErr('Password and Confirm Password are not matched');
      this.stopProgress();
      return;
    }
    let [err] = await to(this.auth.changePw({
      old_pw: data.oldPw,
      new_pw: data.newPw,
    }).toPromise());

    if (err) {
      this.showErr(err);
      this.stopProgress();
    } else {
      this.stopProgress();
      this.showSuccess('Password changed successfully. Please login again.');
      this.auth.logout();
      window.location.href = '/login';
    }

  }

}
