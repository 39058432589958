import { Observable } from "rxjs";
import { ApiService } from "./api.service";

import { environment } from "@env/environment";

export class PlanningService {
    api: ApiService = null
    baseUrl: string = null

    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.planningUrl
    }

    public listActiveSession() {
        const url = `${this.baseUrl}/sessions`
        return this.api.GET(url)
    }

    public getSessionDetail(id) {
        const url = `${this.baseUrl}/sessions/${id}`
        return this.api.GET(url)
    }
    public getSessionExportedRoutes(id) {
        const url = `${this.baseUrl}/sessions/${id}/exported`
        return this.api.GET(url)
    }

    public getSessionShipments(id) {
        const url = `${this.baseUrl}/sessions/${id}/shipments?full=true`
        return this.api.GET(url)
    }

    public removeSessionShipment(id, shipmentId) {
        const url = `${this.baseUrl}/sessions/${id}/shipments/${shipmentId}`
        return this.api.DELETE(url)
    }

    public addVehicle(id, vehicle) {
        const url = `${this.baseUrl}/sessions/${id}/fleet`
        return this.api.POST(url, vehicle)
    }

    public setGroup(id, shipmentIds, groupName) {
        const url = `${this.baseUrl}/sessions/${id}/shipments/set-group`
        return this.api.POST(url, {shipmentIds, groupName})
    }

    public unsetGroup(id, shipmentIds) {
        const url = `${this.baseUrl}/sessions/${id}/shipments/unset-group`
        return this.api.POST(url, {shipmentIds})
    }

    public removeVehicle(id, vehicle) {
        const url = `${this.baseUrl}/sessions/${id}/fleet/${vehicle.id}`
        return this.api.DELETE(url)
    }

    public addShipmentToPlanning(id) {
        const url = `${this.baseUrl}/shipments/${id}/sessions`
        return this.api.POST(url)
    }

    public triggerRouting(id) {
        const url = `${this.baseUrl}/sessions/${id}/trigger-routing`
        return this.api.POST(url)
    }

    public notifyExport(id, jobId, solutionId, routeId) {
        const url = `${this.baseUrl}/sessions/${id}/export`
        return this.api.POST(url, {
            jobId,
            routeId,
            solutionId
        })
    }

    public notifyExporting(id, solutionId, routeId, shipmentIds) {
        const url = `${this.baseUrl}/sessions/${id}/exporting`
        return this.api.POST(url, {
            solutionId,
            routeId,
            shipmentIds
        })
    }

    public loadShipmentPlanningSessions(shipmentIds) {
        const url = `${this.baseUrl}/sessions/search`
        return this.api.POST(url, {
            shipmentIds,
        })
    }

    public getJob(jobId) {
        const url = `${this.baseUrl}/job/${jobId}`
        return this.api.GET(url)
    }

    public loadJobTasks(jobId) {
        const url = `${this.baseUrl}/job/${jobId}/tasks`
        return this.api.GET(url)
    }

    public loadJobsDeliveryStatus(ids) {
        const url = `${this.baseUrl}/job/delivery-status`
        return this.api.POST(url, {ids})
    }
}