import to from 'await-to-js';
import { Component } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Crypto } from '@services/crypto';
import { InputHelper } from "@services/input-helper";
import { ActivatedRoute } from "@angular/router";
import { Log } from "@services/log";
import { verifyAfterLogin } from "@services/auth.check-role";

@Component({
  selector: '[verify-login]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../public/style.scss', '../../styles/form-v1.scss']
})
export class VerifyLogin extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    otp: {label: 'Code:', required: true, placeHolder: 'Enter Code'}
  };
  private email = '';
  private pw = '';

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.loginData) {
        try {
          const {email, pw} = JSON.parse(Crypto.decryptText(p.loginData));
          this.email = email;
          this.pw = pw;
        }
        catch (e) {
          Log.e('Parse loginData error: ', e);
          this.router.navigate([this.routeLogin]);
        }
      }
    });
  }

  get canVerify() {
    return !this.onProgress && this.needUpdate;
  }

  onOtpKeyUp(event: KeyboardEvent) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    if (this.canVerify) {
      this.onBtnVerify();
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async onBtnVerify() {
    this.startProgress();
    const data: any = this.getFormData_JSON(true);
    let [err] = await to(this.auth.login(this.email, this.pw, data.otp).toPromise());
    if (err) {
      this.showErr(err);
    } else {
      await this.loginSuccess();
    }
    this.stopProgress();


    // const data: any = this.getFormData_JSON(true);
    // let params = {
    //   data: this.token,
    //   otp: data?.otp,
    // };
    // this.startProgress();
    // this.api.POST(Const.API_AUTH('login'), params).subscribe(
    //   resp => {
    //     Log.d('loginSuccess ', resp);
    //     this.stopProgress();
    //     this.loginSuccess(resp);
    //   }, err => {
    //     Log.e('loginFailed', err);
    //     this.stopProgress();
    //     this.showErr(err);
    //   }
    // );
  }
  
  private async loginSuccess() {
    await this.appComponent.loginSucceeded();
    verifyAfterLogin(this.authUser, this.router);
  }

}
