import { EnvType } from './type';

export const environment = {
  type: EnvType.dev4,
  socketUrl: 'https://main4.dev.wearewarp.com',
  backendUrl: 'https://main4.dev.wearewarp.com',
  authUrl: 'https://auth.dev.wearewarp.com',
  legacyDriverWebUrl: 'https://driver-web.dev.wearewarp.com',
  driverWebUrl: 'https://driver-web.dev.wearewarp.com',
  adminWebUrl: 'https://admin-4.dev.wearewarp.com',
  customerWebUrl: 'https://customer-4.dev.wearewarp.com',
  carrierWebUrl: 'https://carrier-4.dev.wearewarp.com',
  warehouseWebUrl: 'https://warehouse-4.dev.wearewarp.com',
  trackingWebUrl: 'https://tracking-4.dev.wearewarp.com',
  routingUrl: '',
  ratingUrl: '',
  routingAppUrl: '',
  fileServer: '',
  zipcodeUrl: 'https://gw.wearewarp.com/us-cities/v1/zipcode',
  twofaRequired: false,
  eventUrl: 'https://dev-event.wearewarp.link',
  routing2Url: '',
  planningUrl: '',
  dataorchUrl: '',
  wsUrl: '',
  restWarehouseBackendUrl: '',
  commlogServiceUrl: "https://commlog.dev.wearewarp.com"
};
