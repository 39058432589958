export class ImageUtil {
  public static readonly UPLOAD_IMAGE_MAX_SIZE = 1024*1024*2;     // 2MiB
  public static readonly UPLOAD_IMAGE_MAX_WIDTH = 1920;           // pixel

  public static resizeImageIfNeeded(file: File): Promise<Blob> {
    let isImage = file.type.startsWith('image/');
    let isBigSize = file.size > ImageUtil.UPLOAD_IMAGE_MAX_SIZE;
    if (isImage && isBigSize) {
      return ImageUtil.resizeImage(file);
    } else {
      return Promise.resolve(file);
    }
  }

  public static resizeImage(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (event) => {
        let image = new Image();
        image.onload = imageEvent => {
          let width = image.width;
          let height = image.height;
          const max_size = ImageUtil.UPLOAD_IMAGE_MAX_WIDTH;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          let canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          let dataUrl = canvas.toDataURL('image/jpeg');
          let resizedImage = this.dataURLToBlob(dataUrl);
          return resolve(resizedImage);
        }
        image.src = <string>(<FileReader>event.target).result;
      }
      reader.readAsDataURL(file);
    });
  }

  public static dataURLToBlob(dataURL): Blob {
    let BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      let parts = dataURL.split(',');
      let contentType = parts[0].split(':')[1];
      let raw = parts[1];
      return new Blob([raw], {type: contentType});
    }
    let parts = dataURL.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }
  
}