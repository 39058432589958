import { MasterData } from './master.data';
import { Const } from '@const/Const';
import mapboxgl, { LngLatLike } from 'mapbox-gl';

interface PopupPointFeature {
  coordinates: [number, number],
  description: string,
  isShowMapPopupDefault: boolean,
  color: string,
}

export class Mapbox {
  public map: any;

  public currentSources = [];
  public currentLayers = [];
  public currentImage = [];
  public currentPopups = [];
  public center = <LngLatLike>Const.MAP_LOCATION_LA;

  constructor(center = null) {
    this.center = center;
  }
  protected geojsonPoint(coordinates) {
    return {
      'type': 'FeatureCollection',
      'features': [{
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': coordinates
        }
      }]
    };
  }

  protected loadPoint(map, point, index, color, type = '') {
    const time = Date.now();
    const id = `point-${time}-${type}-${index}`
    map.addSource(id, {
      'type': 'geojson',
      'data': this.geojsonPoint(point)
    });

    map.addLayer({
      'id': id,
      'type': 'circle',
      'source': id,
      'paint': {
        'circle-radius': 10,
        'circle-color': color // red color
      }
    });
    this.currentSources.push(id);
    this.currentLayers.push(id);
  }

  protected loadImage(map, imageLocations: Array<any>, imageSource: string) {
    let addImage = image => {
      // Add the image to the map style.
      map.addImage('img', image);
      this.currentImage.push('img');
      // Add a data source containing one point feature.
      imageLocations.forEach((item, index) => {
        if (item?.location?.longitude && item?.location?.latitude) {
          map.addSource(`image-${index}`, {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': [item.location.longitude, item.location.latitude]
                  }
                }
              ]
            }
          });
          // Add a layer to use the image to represent the data.
          map.addLayer({
            'id': `image-${index}`,
            'type': 'symbol',
            'source': `image-${index}`, // reference the data source
            'layout': {
              'icon-image': 'img', // reference the image
              'icon-size': 0.25
            }
          });
          this.currentSources.push(`image-${index}`);
          this.currentLayers.push(`image-${index}`);
        }
      });
    }
    if (imageSource.endsWith('.svg')) {
      // for svg
      let img = new Image();
      img.onload = () => addImage(img)
      img.src = imageSource
    } else {
      // for png, jpeg,...
      map.loadImage(
        imageSource,
        (error, image) => {
          if (error) throw error;
          addImage(image);
        }
      );
    }
  }

  public clearMap() {
    const waiting = () => {
      if (!this.map?.ready()) {
        setTimeout(waiting);
      } else {
        this.clearLayerSoureImg();
      }
    };
    waiting();
  }

  public addPoints({points, color, des}) {
    if (!points?.length) {
      return;
    }
    const waiting = () => {
      if (!this.map?.ready()) {
        setTimeout(waiting, 300);
      } else {
        for (let index = 0; index < points.length; index++) {
          const point = points[index];
          this.loadPoint(this.map, point, index, color, des || 'point');
        }
      }
    };
    waiting();
  }

  public addRoute(routes, isFitBoundsMap = false) {
    const colorPick = Const.MAP_LOCATION_COLOR_PICK;
    const colorDrop = Const.MAP_LOCATION_COLOR_DROP;
    const waiting = () => {
      if (!this.map?.isMapReady) {
        setTimeout(waiting, 200);
      } else {
        this.loadRoute(this.map, routes, colorPick, colorDrop, false);
        if (isFitBoundsMap) {
          this.fitBoundsMap(this.map, routes, {
            padding: 50,
            maxZoom: 15
          });
        }
      }
    };
    waiting();
  }

  protected loadRoute(map, routes, colorPick, colorDrop, isLoadPoint = true) {
    const time = Date.now();
    routes.forEach((route, index) => {
      const id = `route-${time}-${index}`;
      map.addSource(id, {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': route
          }
        }
      });
      map.addLayer({
        'id': id,
        'type': 'line',
        'source': id,
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': Const.MAP_ROUTE_LINE_COLOR,
          'line-width': 3
        }
      });
      this.currentSources.push(id);
      this.currentLayers.push(id);
      if (isLoadPoint) {
        this.loadPoint(map, route[0], index, colorPick, 'pickup');
        this.loadPoint(map, route[route.length - 1], index, colorDrop, 'dropoff')
      }
    });
  }

  protected forEachLayer(text, cb) {
    this.map.getStyle().layers.forEach((layer) => {
      if (!layer.id.includes(text)) return;
      cb(layer);
    });
  }

  protected fitBoundsMap(map, points, options = { padding: 100, maxZoom: 15}) {
    const bounds = new mapboxgl.LngLatBounds(
      points[0][0],
      points[0][0]
    );
    for (let i = 0; i < points.length; i++) {
      let route = points[i];
      if (i > 0) {
        bounds.extend(route[0]);
      }
      bounds.extend(route[route.length - 1]);
    }
    map.fitBounds(bounds, options);
  }

  protected fitBoundsMapPoints(map, points) {
    const bounds = new mapboxgl.LngLatBounds(
      points[0][0][0],
      points[0][0][0]
    );
    for (let i = 0; i < points.length; i++) {
      let route = points[i];
      const routePickUps = route[0];
      routePickUps.forEach((routePick, index) => {
        bounds.extend(routePick);
      });
      const routeDropoffs = route[1];
      routeDropoffs.forEach((routeDropoff) => {
        bounds.extend(routeDropoff);
      });
    }
    map.fitBounds(bounds, {
      padding: 100,
      maxZoom: 15,
    });
  }

  protected clearLayerSoureImg() {
    this.currentLayers.forEach((item) => {
      if (this.map.getLayer(item)) {
        this.map.removeLayer(item);
      }
    });
    this.currentSources.forEach((item) => {
      if (this.map.getSource(item)) {
        this.map.removeSource(item);
      }
    });
    this.currentImage.forEach((item) => {
      if (this.map.hasImage(item)) {
        this.map.removeImage(item);
      }
    });
    this.currentPopups.forEach(popup => {
      popup.remove();
    });

    this.currentSources = [];
    this.currentLayers = [];
    this.currentImage = [];
    this.currentPopups = [];
  }

  protected addLayerSourceImage(routes, driverLocations, points, colorPick, colorDrop) {
    if (routes.length > 0) {
      this.loadRoute(this.map, routes, colorPick, colorDrop);
    }
    if (driverLocations.length > 0) {
      this.loadImage(this.map, driverLocations, 'assets/img/warp-box-truck.svg');
    }
    if (points.length) {
      points.forEach((point, index) => {
        point[0].forEach((p, i) => {
          this.loadPoint(this.map, p, `p-${index}-${i}`, colorPick, 'pickup');
        })
        point[1].forEach((p, i) => {
          this.loadPoint(this.map, p, `p-${index}-${i}`, colorDrop, 'dropoff')
        })
      })
    }
  }

  public setStyleMap(style = Const.MapBox.style.light) {
    this.map.setStyle(Const.MAPBOX_STYLE(style));
  }

  public setupMap(routes = [], points = [], driverLocations = [], style = Const.MapBox.style.light, byFitBounds = Const.MapBox.FitBoundsBy.routes, mapId = 'dispatch-map') {
    const colorPick = Const.MAP_LOCATION_COLOR_PICK;
    const colorDrop = Const.MAP_LOCATION_COLOR_DROP;
    let center = <LngLatLike>Const.MAP_LOCATION_LA;
    if (routes.length > 0) {
      center = [routes[0][0][0], routes[0][0][1]];
    } else if (points.length) {
      center = [points[0][0][0][0], points[0][0][0][1]];
    }
    if (!this.map) {
      mapboxgl.accessToken = MasterData.mapboxToken;
      this.map = new mapboxgl.Map({
        container: mapId,
        style: Const.MAPBOX_STYLE(style),
        center: <any>center,
        zoom: 12
      });
      this.map.isMapReady = false;
      this.map.ready = () => this.map.isMapReady;
      this.map.once('load', () => this.map.isMapReady = true);
    } else {
      this.clearLayerSoureImg();
      this.addLayerSourceImage(routes, driverLocations, points, colorPick, colorDrop);
    }
    let features = [];
    this.map.on('style.load', () => {
      this.addLayerSourceImage(routes, driverLocations, points, colorPick, colorDrop);
    });
    if (routes.length > 0 && byFitBounds == Const.MapBox.FitBoundsBy.routes) {
      this.fitBoundsMap(this.map, routes);
    } else if (points.length) {
      this.fitBoundsMapPoints(this.map, points);
    }
  }
  public addPopupPoint(points: Array<PopupPointFeature>, color = '#51bbd6', layer = null) {
    const waiting = () => {
      if (!this.map.ready()) {
        setTimeout(waiting, 200);
      } else {
        this.setPopupPoint(points, color, layer);
      }
    };
    waiting();
  }

  protected setPopupPoint(points: Array<PopupPointFeature>, color = '#51bbd6', layer = null) {
    const features = [];
    let layerId = 'source-popup';
    let sourceId = `source-popup-${this.currentSources.length}`;
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: true
    });
    this.currentPopups.push(popup);
    points.forEach(point => {
      features.push({
        'type': 'Feature',
        'properties': {
          'description': point.description
        },
        'geometry': {
          'type': 'Point',
          'coordinates': point.coordinates
        }
      });
      if (point.isShowMapPopupDefault) {
        this.showMapPopup(popup, point.coordinates, point.description);
      }
    });
    this.map.addSource(sourceId, {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': features
      }
    });

    if (layer) {
      layerId = layer.id;
      layer.source = sourceId;
      this.map.addLayer(layer);
    } else {
      layerId = `source-popup-${this.currentLayers.length}`;
      this.map.addLayer({
        'id': layerId,
        'type': 'circle',
        'source': sourceId,
        'paint': {
          'circle-radius': 10,
          'circle-color': color,
        }
      });
    }
    this.currentSources.push(sourceId);
    this.currentLayers.push(layerId);
    this.map.on('click', layerId, (e) => {
      e.originalEvent.stopPropagation();
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;
      this.showMapPopup(popup, coordinates, description);
    });
    this.map.on('mousemove', layerId, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
    })
    this.map.on('mouseleave', layerId, () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  protected showMapPopup(popup, coordinates = [0, 0], html = '') {
    popup.setLngLat(coordinates).setHTML(html).addTo(this.map);
  }

  public showMapNewPopup(coordinates = <LngLatLike>[0, 0], html = '') {
    this.currentPopups.forEach(popup => {
      popup.remove();
    });
    const popup: any = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: true
    });
    this.currentPopups.push(popup);
    popup.setLngLat(coordinates).setHTML(html).addTo(this.map);
    this.map.flyTo({
      center: coordinates
    });
  }
  public getMapbox(id = 'dispatch-map') {
    if (!this.map) {
      mapboxgl.accessToken = MasterData.mapboxToken;
      this.map = new mapboxgl.Map({
        container: id,
        style: Const.MAPBOX_STYLE_STREETS_V11,
        center: <any>this.center,
        zoom: 9
      });
      this.map.isMapReady = false;
      this.map.ready = () => this.map.isMapReady;
      this.map.once('load', () => this.map.isMapReady = true);
    }
    return this.map;
  }
  public remove() {
    if (this.map) {
      this.map.remove();
    }
  }

  toRadians(degrees) {
    return degrees * Math.PI / 180;
  };

  // Converts from radians to degrees.
  toDegrees(radians) {
    return radians * 180 / Math.PI;
  }


  public bearing(fromLocation, destLocation) {
    let startLat = this.toRadians(fromLocation?.latitude);
    let startLng = this.toRadians(fromLocation?.longitude);
    let destLat = this.toRadians(destLocation?.latitude);
    let destLng = this.toRadians(destLocation?.longitude);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = this.toDegrees(brng);
    return (brng + 360) % 360;
  }

  //return distance (in m)
  public getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = this.toRadians(lat2 - lat1);
    const dLon = this.toRadians(lon2 - lon1);
    lat1 = this.toRadians(lat1);
    lat2 = this.toRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000;
  }

  public getSpeed(lat1, lon1, lat2, lon2, time1, time2) {
    const distance = this.getDistance(lat1, lon1, lat2, lon2);
    const speed = distance * 1000 / (time2 - time1); //m/s
    return (speed * 2.2369362921).toFixed(2) || 0 //mph
  }


}