import { ApiService } from "./api.service";

import { environment } from "@env/environment";
import TTLCache from "@isaacs/ttlcache";
import { HttpService } from "@wearewarp/ng-web/auth/http-service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


export class EventService {
    api: ApiService = null
    baseUrl: string = null

    constructor(api: ApiService, baseUrl?) {
        this.api = api
        this.baseUrl = baseUrl ?? environment.eventUrl
    }

    listEvents(object, type = 'all') {
        let url = `${this.baseUrl}/find/${object}/${type}`;
        return this.api.GET(url)
    }

    getEvent(id) {
        let url = `${this.baseUrl}/events/${id}`;
        return this.api.GET(url)
    }

    reprocessEvent(id) {
        let url = `${this.baseUrl}/events/${id}/reprocess`;
        return this.api.POST(url)
    }
}