<div class="lp-form-title">
  Your password has expired!
</div>

<div class="lp-form-description">
  Please change your password to continue.
</div>

<form [formGroup]="formInput" nz-form>
  <ng-container *ngFor="let key of formInputKeys">
    <div class="form-label-v1">{{getLabel(key)}}</div>
    <nz-form-item class="form-value-v1">
      <ng-container [ngSwitch]="key">
        <input #inputCode nz-input
          [formControlName]="key" type="text" name="otp"
          [placeholder]="getPlaceHolder(key)"
          (input)="onInputChanged($event, key)"
          (keypress)="onInputKeyPress($event, key)"
          type="password"
        />
      </ng-container>
    </nz-form-item>
  </ng-container>
</form>

<button nz-button class="lp-button-border submit" [disabled]="!canVerify || onProgress" (click)="onBtnVerify()">
  <i *ngIf="onProgress" class="ic-right" nz-icon nzType="loading" nzTheme="outline"></i>Change Password
</button>
